import React from 'react';
import styled from 'styled-components';
import LogoImage from './su.jpg';
import { Link } from 'gatsby';

export const Navbar: React.FC<any> = () => {
  return (
    <Container>
      <nav className="navbar navbar-main">
        <div className="container">
          <div className="navbar-brand is-flex-center">
            <a className="navbar-item navbar-logo" href="/">
              <img src={LogoImage} alt="すころぐ" height="28" />
            </a>
          </div>
          <div className="navbar-menu">
            <div className="navbar-start">
              <Link to="/" className="navbar-item" activeClassName="is-active">
                ホーム
              </Link>
              {/*<a className="navbar-item" href="/archives/">アーカイブ</a>*/}
              <Link
                className="navbar-item"
                activeClassName="is-active"
                to="/categories/"
              >
                カテゴリー
              </Link>
              <Link
                className="navbar-item"
                activeClassName="is-active"
                to="/tags/"
              >
                タグ
              </Link>
              <Link
                className="navbar-item"
                activeClassName="is-active"
                to="/books/"
              >
                エンジニアへの道
              </Link>
              <Link
                className="navbar-item"
                activeClassName="is-active"
                to="/about/"
              >
                概要
              </Link>
            </div>
            {/*<div className="navbar-end">*/}
            {/*  <a className="navbar-item" target="_blank" title="GitHub" href="https://github.com/su-u">*/}
            {/*    <i className="fab fa-github"></i>*/}
            {/*  </a>*/}
            {/*  <a className="navbar-item search" title="Search" href="">*/}
            {/*    <i className="fas fa-search"></i>*/}
            {/*  </a>*/}
            {/*</div>*/}
          </div>
        </div>
      </nav>
    </Container>
  );
};

const Container = styled.div`
  .navbar-main {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);

    .navbar-menu,
    .navbar-start,
    .navbar-end {
      align-items: stretch;
      display: flex;
      padding: 0;
      flex-shrink: 0;
    }
    .navbar-menu {
      flex-grow: 1;
      flex-shrink: 0;
      overflow-x: auto;
    }
    .navbar-start {
      justify-content: flex-start;
      margin-right: auto;
    }
    .navbar-end {
      justify-content: flex-end;
      margin-left: auto;
    }
    .navbar-item {
      display: flex;
      align-items: center;
      padding: 1.25rem 0.75rem;
      &.is-active {
        color: hsl(217, 71%, 53%);
        background-color: transparent;
      }
    }
    .navbar-logo > img {
      width: 28px;
      height: 28px;
    }
    @media screen and (max-width: 1087px) {
      .navbar-menu {
        justify-content: center;
        box-shadow: none;
      }
      .navbar-start {
        margin-right: 0;
      }
      .navbar-end {
        margin-left: 0;
      }
    }
  }
`;
